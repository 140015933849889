import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/superadmin/login',
  },
  {
    path: '/superadmin/login',
    name: 'superadmin-login',
    component: () => import('@/views/superadmin/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/superadmin/dashboard',
    name: 'superadmin-dashboard',
    component: () => import('@/views/superadmin/Dashboard.vue'),
  },
  {
    path: '/superadmin/jenisbarang',
    name: 'jenisbarang-index',
    component: () => import('@/views/superadmin/jenisbarang/JenisbarangIndex.vue'),
  },
  {
    path: '/superadmin/jenisbarang/create',
    name: 'jenisbarang-create',
    component: () => import('@/views/superadmin/jenisbarang/JenisbarangCreate.vue'),
  },
  {
    path: '/superadmin/jenisbarang/edit/:id_jenisbarang',
    name: 'jenisbarang-edit',
    component: () => import('@/views/superadmin/jenisbarang/JenisbarangEdit.vue'),
  },
  {
    path: '/superadmin/kategoribarang',
    name: 'kategoribarang-index',
    component: () => import('@/views/superadmin/kategoribarang/KategoribarangIndex.vue'),
  },
  {
    path: '/superadmin/kategoribarang/create',
    name: 'kategoribarang-create',
    component: () => import('@/views/superadmin/kategoribarang/KategoribarangCreate.vue'),
  },
  {
    path: '/superadmin/kategoribarang/edit/:id_kategori',
    name: 'kategoribarang-edit',
    component: () => import('@/views/superadmin/kategoribarang/KategoribarangEdit.vue'),
  },
  {
    path: '/superadmin/cabang',
    name: 'cabang-index',
    component: () => import('@/views/superadmin/cabang/CabangIndex.vue'),
  },
  {
    path: '/superadmin/cabang/create',
    name: 'cabang-create',
    component: () => import('@/views/superadmin/cabang/CabangCreate.vue'),
  },
  {
    path: '/superadmin/cabang/edit/:id_cabang',
    name: 'cabang-edit',
    component: () => import('@/views/superadmin/cabang/CabangEdit.vue'),
  },
  {
    path: '/superadmin/supplier',
    name: 'supplier-index',
    component: () => import('@/views/superadmin/supplier/SupplierIndex.vue'),
  },
  {
    path: '/superadmin/supplier/create',
    name: 'supplier-create',
    component: () => import('@/views/superadmin/supplier/SupplierCreate.vue'),
  },
  {
    path: '/superadmin/supplier/edit/:id_supplier',
    name: 'supplier-edit',
    component: () => import('@/views/superadmin/supplier/SupplierEdit.vue'),
  },
  {
    path: '/superadmin/elektronik',
    name: 'elektronik-index',
    component: () => import('@/views/superadmin/elektronik/ElektronikIndex.vue'),
  },
  {
    path: '/superadmin/elektronik/create',
    name: 'elektronik-create',
    component: () => import('@/views/superadmin/elektronik/ElektronikCreate.vue'),
  },
  {
    path: '/superadmin/elektronik/edit/:id_elektronik',
    name: 'elektronik-edit',
    component: () => import('@/views/superadmin/elektronik/ElektronikEdit.vue'),
  },
  {
    path: '/superadmin/barang',
    name: 'barang-index',
    component: () => import('@/views/superadmin/barang/BarangIndex.vue'),
  },
  {
    path: '/superadmin/barang/create',
    name: 'barang-create',
    component: () => import('@/views/superadmin/barang/BarangCreate.vue'),
  },
  {
    path: '/superadmin/barang/edit/:id_barang',
    name: 'barang-edit',
    component: () => import('@/views/superadmin/barang/BarangEdit.vue'),
  },
  {
    path: '/superadmin/transaksipembelian',
    name: 'transaksipembelian-index',
    component: () => import('@/views/superadmin/transaksipembelian/TransaksipembelianIndex.vue'),
  },
  {
    path: '/superadmin/transaksipembelian/create',
    name: 'transaksipembelian-create',
    component: () => import('@/views/superadmin/transaksipembelian/TransaksipembelianCreate.vue'),
  },
  {
    path: '/superadmin/user',
    name: 'user-index',
    component: () => import('@/views/superadmin/user/UserIndex.vue'),
  },
  {
    path: '/superadmin/user/create',
    name: 'user-create',
    component: () => import('@/views/superadmin/user/UserCreate.vue'),
  },
  {
    path: '/superadmin/user/edit/:id_user',
    name: 'user-edit',
    component: () => import('@/views/superadmin/user/UserEdit.vue'),
  },
  {
    path: '/superadmin/jeniscustomer',
    name: 'jeniscustomer-index',
    component: () => import('@/views/superadmin/jeniscustomer/JenisCustomerIndex.vue'),
  },
  {
    path: '/superadmin/jeniscustomer/create',
    name: 'jeniscustomer-create',
    component: () => import('@/views/superadmin/jeniscustomer/JenisCustomerCreate.vue'),
  },
  {
    path: '/superadmin/jeniscustomer/edit/:id_jeniscustomer',
    name: 'jeniscustomer-edit',
    component: () => import('@/views/superadmin/jeniscustomer/JenisCustomerEdit.vue'),
  },
  {
    path: '/superadmin/customer',
    name: 'customer-index',
    component: () => import('@/views/superadmin/customer/CustomerIndex.vue'),
  },
  {
    path: '/superadmin/customer/create',
    name: 'customer-create',
    component: () => import('@/views/superadmin/customer/CustomerCreate.vue'),
  },
  {
    path: '/superadmin/customer/edit/:id_customer',
    name: 'customer-edit',
    component: () => import('@/views/superadmin/customer/CustomerEdit.vue'),
  },
  {
    path: '/superadmin/satuan',
    name: 'satuan-index',
    component: () => import('@/views/superadmin/satuan/SatuanIndex.vue'),
  },
  {
    path: '/superadmin/satuan/create',
    name: 'satuan-create',
    component: () => import('@/views/superadmin/satuan/SatuanCreate.vue'),
  },
  {
    path: '/superadmin/satuan/edit/:id_satuan',
    name: 'satuan-edit',
    component: () => import('@/views/superadmin/satuan/SatuanEdit.vue'),
  },
  {
    path: '/superadmin/inventori',
    name: 'inventori-index',
    component: () => import('@/views/superadmin/inventori/InventoriIndex.vue'),
  },
  {
    path: '/superadmin/inventori/create',
    name: 'inventori-create',
    component: () => import('@/views/superadmin/inventori/InventoriCreate.vue'),
  },
  {
    path: '/superadmin/settingstockopname',
    name: 'settingstockopname-index',
    component: () => import('@/views/superadmin/settingstockopname/SettingStockOpnameIndex.vue'),
  },
  {
    path: '/superadmin/settingstockopname/create',
    name: 'settingstockopname-create',
    component: () => import('@/views/superadmin/settingstockopname/SettingStockOpnameCreate.vue'),
  },
  {
    path: '/superadmin/settingstockopname/edit/:id_stockopname',
    name: 'settingstockopname-edit',
    component: () => import('@/views/superadmin/settingstockopname/SettingStockOpnameEdit.vue'),
  },
  {
    path: '/superadmin/detailstockopname',
    name: 'detailstockopname-index',
    component: () => import('@/views/superadmin/detailstockopname/DetailStockOpnameIndex.vue'),
  },
  {
    path: '/superadmin/detailstockopname/create',
    name: 'detailstockopname-create',
    component: () => import('@/views/superadmin/detailstockopname/DetailStockOpnameCreate.vue'),
  },
  {
    path: '/superadmin/transaksipenjualan',
    name: 'transaksipenjualan-index',
    component: () => import('@/views/superadmin/transaksipenjualan/TransaksiPenjualanCreate.vue'),
  },
  {
    path: '/superadmin/dokumentasi',
    name: 'dokumentasi-index',
    component: () => import('@/views/superadmin/dokumentasi/DokumentasiIndex.vue'),
  },
  // {
  //   path: '/superadmin/detailstockopname/edit/:id_detail_stockopname',
  //   name: 'detailstockopname-edit',
  //   component: () => import('@/views/superadmin/detailstockopname/DetailStockOpnameEdit.vue'),
  // },

  // {
  //   path: '/admin/roles',
  //   name: 'roles-index',
  //   component: () => import('@/views/admin/roles/RolesIndex.vue'),
  // },
  // {
  //   path: '/admin/roles/create',
  //   name: 'roles-create',
  //   component: () => import('@/views/admin/roles/RolesCreate.vue'),
  // },
  // {
  //   path: '/admin/roles/edit/:id_role',
  //   name: 'roles-edit',
  //   component: () => import('@/views/admin/roles/RolesEdit.vue'),
  // },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
